(function () {
    var $globalConf = $('.js-global-conf');
    var userIsLoggued = $globalConf.data('user-is-loggued');

    var mqDetector;
    var notification;
    var searchBox;
    var $previousQueriesWrapper = $('.js-previous-searches-wrapper');
    var $browser = $('.js-browser');
    var intervalId;
    var $navbarBtn = $('.ee-mod .navbar-toggle');
    var $stateNav = $('.ee-ico-state-nav');
    var $btnLogOff = $('.js-logOff');

    notification = new Notification();

    eeGlobal.notification = notification;

    mqDetector = new MediaQueryDetector({
        $target: $('#mq-detector')
    });

    eeGlobal.mqDetector = mqDetector;

    /*
      Init SearchBox module
    */
    searchBox = new SearchBox({
        $input: $('.js-searchbox-input'),
        $form: $('.js-searchbox-form')
    });
    eeGlobal.searchBox = searchBox;

    //ajaxComplete permite interceptar la request Ajax y examinar la repuesta para determinar si la session caduco
    //Se realiza por seguridad ya que no deberia continuar en la vista actual si la session caduco
    //Archivos modificados: Main.js, MainSimple.js
    $(document).ajaxComplete(function (event, xhr, settings) {
        if (xhr.status === 401) {
            location.reload();
            return;
        }

        var jsonObject = xhr.responseJSON;
        if (jsonObject !== null && jsonObject !== undefined && jsonObject.hasOwnProperty('isSessionExpired') && jsonObject.isSessionExpired) {
            window.location.href = jsonObject.url;
        }
    });

    // Remove local storage for brands
    function removeBrandsLocalStorage() {
        if (store.enabled && store.get('se-brands-selected')) {
            store.remove('se-brands-selected');
        }
    }

    // Truncate previous search
    $(document).ready(function () {
        if (!eeGlobal.mqDetector.isDevice('xs', 'lg')) {
            $previousQueriesWrapper.trunk8({
                lines: 2,
                tooltip: false
            });
        }
    });

    //Function to detect navigator ie
    detectIE = function () {
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf('MSIE ');
        var version = -1;
        if (msie > 0) {
            // IE 10 or older => return version number
            version = parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }
        if (version === -1 || version > 8) {
            clearInterval(intervalId);
        }
        else {
            $browser.removeClass('hide');
        }
    };
    detectIE();
    intervalId = setInterval(detectIE, 216000000);

    // Log off functions
    $btnLogOff.on('click', function () {
        removeBrandsLocalStorage();
    });

    // Expired session
    if (!userIsLoggued && store.enabled && store.get('userIdSites')) {
        // Remove user id when user is not loggued
        store.remove('userIdSites');
        userId = 0;
    }

    /*
    Doble binding*/
    $navbarBtn.on('click', function (event) {
        $stateNav.toggleClass("hidden-ico");
    });
})();
