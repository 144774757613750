var isAsyncFileUploadCapable;
var isFileCapable;
var isXHR2Capable;

// Vars to invalidHandler default method
var iOS;
var $htmlBody;
var $invalidElement;

// Testing browser
isXHR2Capable = (new XMLHttpRequest().upload);
isFileCapable = (window.File && window.FileReader && window.FileList && window.Blob);
isAsyncFileUploadCapable = isXHR2Capable && isFileCapable;

// Init vars to invalidHandler default method
iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
$htmlBody = $('html, body');

jQuery.validator.setDefaults({
  focusInvalid: false,
  validClass: 'has-success',
  highlight: function (element, errorClass, validClass) {
    var elementType = $(element).prop('type');
    var $targetElement = $(element).closest('.form-group');
    // Special case, because that data special input
    if ($targetElement.children('label').hasClass('sr-only')) {
      $targetElement = $targetElement.parent().closest('.form-group');
    }
    $targetElement.addClass(errorClass).removeClass(validClass);
    if (elementType === 'checkbox' || elementType === 'radio') {
      $targetElement
        .find('.checkbox, .radio')
        .addClass('has-error');
    }
  },
  unhighlight: function (element, errorClass, validClass) {
    var elementType = $(element).prop('type');
    var $targetElement = $(element).closest('.form-group');
    // Special case, because that data special input
    if ($targetElement.children('label').hasClass('sr-only')) {
      $targetElement = $targetElement.parent().closest('.form-group');
    }
    if (!$targetElement.hasClass('js-customDate')
      && !$targetElement.hasClass('js-customDateMonthYear')) {
      $targetElement.addClass(validClass).removeClass(errorClass);
      if (elementType === 'checkbox' || elementType === 'radio') {
        $targetElement
          .find('.checkbox, .radio')
          .removeClass('has-error');
      }
    }
    // Custom validation for DateControl
    else if (($targetElement.hasClass('js-customDate') || $targetElement.hasClass('js-customDateMonthYear'))
              && !$targetElement.hasClass('has-error')
              && $targetElement.find('.js-datemodel').val() !== undefined
              && $targetElement.find('.js-datemodel').val() !== null
              && $targetElement.find('.js-datemodel').val() !== '') {
      $targetElement.addClass(validClass).removeClass(errorClass);
    }
  },
  onkeyup: function(element, event) {
    var $el = $(element);

    if($el.data('val-remotevalidation')) {
      $el.valid();
    };
  }
});

jQuery.validator.unobtrusive.options = {
  focusInvalid: false,
  errorClass: 'has-error',
  errorPlacement: function ($error, $element) {
    var elementType = $element.prop('type');
    var $helpBlock;
    if (elementType === 'checkbox' || elementType === 'radio' || elementType === 'password' ) {
      $helpBlock = $element.closest('.form-group').find('.help-block');
    } else {
      $helpBlock = $element.next('.help-block');
    }
    $error.appendTo($helpBlock);
  },
  invalidHandler: function(form, validator) {
    /*
      TODO: This event not is detected in jquery validator defaults, change to
      that library if this is fixed.
    */
    if (validator.errorList.length <= 0) {
        return;
    }
    $invalidElement = $(validator.errorList[0].element);

    if(iOS) {
      $htmlBody.animate({
        scrollTop: $invalidElement.offset().top - 100
      }, 800, function(){
        $invalidElement.focus();
      });
    } else {
      if (!$invalidElement.is(':visible') && $invalidElement[0].hasAttribute('focusId')) {
        $htmlBody.animate({
          scrollTop: $("#" + $invalidElement.attr("focusId")).offset().top - 100
        }, 100);
      } else {
        $invalidElement.focus();
      }
    }
  }
};

// Fix validation for dates un Chrome and Safari
jQuery.validator.methods.date = function (value, element) {
  var dateToValidate = value.split("/").join('-');
  return this.optional(element) || moment(dateToValidate, 'DD-MM-YYYY').isValid();
};

// Add validation format
jQuery.validator.addMethod(
  "dateFormat",
  function (value, element) {
    var check = false;
    var re = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
    if (re.test(value)) {
      var dateFormatString = value.split('/').join('-');

      if (moment(dateFormatString, 'DD-MM-YYYY').isValid()) check = true;
      else check = false;
    } else {
      check = false;
    }
    return this.optional(element) || check;
  },
  "Please enter a valid date (dd/mm/yyyy)"
);

// Adding Validaton to jQuery Validation API
jQuery.validator.unobtrusive.adapters.add(
  'dateFormat', ['properties'], function (options) {
    options.rules['dateFormat'] = options.params;
    options.messages['dateFormat'] = options.message;
  }
);

$.validator.addMethod('rangeDate', function (value, element, param) {
   var dateValue;

   if (!value) return true;

   try {
     dateValue = getDate(value);
   }
   catch (e) {
     return false;
   }

   return param.min.isBefore(dateValue) && dateValue.isBefore(param.max);
 });

// The adapter to support ASP.NET MVC unobtrusive validation
$.validator.unobtrusive.adapters.add('rangedate', ['min', 'max'], function(options) {
  var params = {
    min: getDate(options.params.min),
    max: getDate(options.params.max)
  };

  options.rules['rangeDate'] = params;
  if (options.message) {
    options.messages['rangeDate'] = options.message;
  }
});

jQuery.validator.addMethod("enforcetrue", function (value, element, param) {
  return element.checked;
});
jQuery.validator.unobtrusive.adapters.addBool("enforcetrue");

jQuery.validator.setDefaults({
  ignore: "not:hidden, .js-ignore-validate"
});

// Adapter for client side validation for date values.
jQuery.validator.addMethod('conditionaldate', function (value, element, params) {

  var dateToValidate = getDateDMY(value);
  var compareProperty = $(element).data('val-conditionaldate-compareproperty');
  var compareType = $(element).data('val-conditionaldate-comparetype');
  var conditionalProperty = $(element).data('val-conditionaldate-conditionalproperty');
  var conditionalValue = $(element).data('val-conditionaldate-conditionalvalue');

  var dateToCompare = getDateDMY($(element).closest('form')
                                           .find("[name='" + compareProperty + "']")
                                           .val());

  if (!dateToValidate) return true;

  if (conditionalProperty != undefined && conditionalProperty != '') {
    var conditionalPropertyValue = $(element).closest('form')
                                             .find("[name='" + conditionalProperty + "']")
                                             .val();

    if (conditionalValue.toString() != conditionalPropertyValue) {
      return true;
    }

    return compareDate(dateToValidate, dateToCompare, compareType);
  }

  return compareDate(dateToValidate, dateToCompare, compareType);

}, '');

jQuery.validator.unobtrusive.adapters.add('conditionaldate', {}, function (options) {
    options.rules['conditionaldate'] = true;
    options.messages['conditionaldate'] = options.message;
});

$.validator.addMethod('restricteddate', function (value, element, param) {
    var dateValue;

    if (!value) return true;

    try {
        dateValue = getDate(value);
    }
    catch (e) {
        return false;
    }

    var compareType = param.comparetype;
    return compareDate(dateValue, param.limit, compareType);
});

// The adapter to support ASP.NET MVC unobtrusive validation
$.validator.unobtrusive.adapters.add('restricteddate', ['limit', 'comparetype'], function (options) {
    var params = {
        limit: getDate(options.params.limit),
        comparetype: options.params.comparetype
    };

    options.rules['restricteddate'] = params;
    if (options.message) {
        options.messages['restricteddate'] = options.message;
    }
});


jQuery.validator.addMethod('validdate', function (value, element, params) {
  var dateToValidate = getDateDMY(value);
  var checkToday = $(element).data('val-validdate-checktoday');
  var minDate = moment('1900-01-01', 'YYYY-MM-DD');
  var maxDate = (checkToday === true)? moment() : moment(8640000000000000);

  if (dateToValidate === null) return true;

  if (dateToValidate.isBefore(minDate)) return false;

  if (dateToValidate.isAfter(maxDate)) return false;

  return true;
}, '');

jQuery.validator.unobtrusive.adapters.add('validdate', {}, function (options) {
  options.rules['validdate'] = true;
  options.messages['validdate'] = options.message;
});

jQuery.validator.unobtrusive.adapters.addSingleVal("minimumfilesize", "size");

jQuery.validator.addMethod('minimumfilesize', function (value, element, minSize) {
  if (isAsyncFileUploadCapable) {
    return convertBytesToMegabytes(element.files[0].size) >= parseFloat(minSize);
  } else {
    return true;
  }
});

jQuery.validator.addMethod('maximumfilesize', function (value, element, maxSize) {
  if (isAsyncFileUploadCapable && element.files[0]) {
    return convertBytesToMegabytes(element.files[0].size) <= parseFloat(maxSize);
  } else {
    return true;
  }
});

jQuery.validator.unobtrusive.adapters.add('maximumfilesize', {}, function (options) {
  options.rules['maximumfilesize'] = $(options.element).data('valMaximumfilesizeSize');
  options.messages['maximumfilesize'] = options.message;
});

jQuery.validator.unobtrusive.adapters.addSingleVal("validfiletype", "filetypes");

jQuery.validator.addMethod('validfiletype', function (value, element, validFileTypes) {
  if (validFileTypes.indexOf(',') > -1) {
    validFileTypes = validFileTypes.split(',');
  } else {
    validFileTypes = [validFileTypes];
  }

  var fileType = value.split('.')[value.split('.').length - 1];

  for (var i = 0; i < validFileTypes.length; i++) {
    if (validFileTypes[i].toLowerCase() === fileType.toLowerCase()) {
      return true;
    }
  }

  return false;
});

jQuery.validator.unobtrusive.adapters.addSingleVal("validfilename", "filenames");

jQuery.validator.addMethod('validfilename', function (value, element, validfilename) {
  var fileName = value.split('\\').pop();
  var reg = new RegExp(validfilename);

  if (reg.test(fileName)) {
    var result = reg.exec(fileName);

    if (result.length > 0) {
      return (result[0] == fileName);
    }
  }

  return false;
});

jQuery.validator.unobtrusive.adapters.add('fileuploadvalidator', ['clientvalidationmethods', 'parameters', 'errormessages'], function (options) {
  options.rules['fileuploadvalidator'] = {
    clientvalidationmethods: options.params['clientvalidationmethods'].split(','),
    parameters: options.params['parameters'].split('|'),
    errormessages: options.params['errormessages'].split(',')
  };
});

jQuery.validator.addMethod("fileuploadvalidator", function (value, element, param) {
  if (value === "" || value === null || value === undefined) {
    return true;
  }
  //array of jquery validation rule names
  var validationrules = param["clientvalidationmethods"];

  //array of paramteres required by rules, in this case regex patterns
  var patterns = param["parameters"];

  //array of error messages for each rule
  var rulesErrormessages = param["errormessages"];

  var validNameErrorMessage = new Array();
  var index = 0;

  for (i = 0; i < patterns.length; i++) {
    var valid = true;
    var pattern = patterns[i].trim();

    //get a jquery validator method.
    var rule = $.validator.methods[validationrules[i].trim()];

    //create a paramtere object
    var parameter = new Object();
    parameter = pattern;

    //execute the rule
    var isValid = rule.call(this, value, element, parameter);

    if (!isValid) {
      //if rule fails, add error message
      validNameErrorMessage[index] = rulesErrormessages[i];
      index++;
    }
  }
  //if we have more than on error message, one of the rule has failed
  if (validNameErrorMessage.length > 0) {
    //update the error message for 'validname' rule
    $.validator.messages.fileuploadvalidator = validNameErrorMessage.toString();
    return false;
  }
  return true;
}, "This is not a valid individual name"//default error message
);

jQuery.validator.unobtrusive.adapters.add(
  'notequalto', ['other'], function (options) {
    var $targetInput = $('[name=' + options.params.other + ']');
    options.rules['notEqualTo'] = '#' + options.params.other;
    if ($targetInput) {
      options.rules['notEqualTo'] = '#' + $targetInput.attr('id');
    }
    if (options.message) {
      options.messages['notEqualTo'] = options.message;
    }
  });

jQuery.validator.addMethod('notEqualTo', function (value, element, param) {
  var $targetInput = $(param);
  var valueSplit = $targetInput.val();

  return this.optional(element) || valueSplit.indexOf(value) < 0;
}, '');

// Configure Typeahead adapters BEGIN
jQuery.validator.addMethod('typeaheadrequired', function (value, element, params) {
  var typeaheadVal = $(element).typeahead('val');

  if ($(element).hasClass('tt-input')
      && (typeaheadVal === undefined || typeaheadVal === null || typeaheadVal === '')) {
      return false;
  }

  return true;
}, '');

jQuery.validator.unobtrusive.adapters.add('typeaheadrequired', {}, function (options) {
  options.rules['typeaheadrequired'] = $(options.element).attr('name');
  options.messages['typeaheadrequired'] = options.message;
});

jQuery.validator.addMethod('typeaheadmaxlength', function (value, element, params) {
  var typeaheadVal = $(element).typeahead('val');
  var maxLength = parseInt(params['maxlength']);

  if ($(element).hasClass('tt-input')
      && (typeaheadVal.length > maxLength)) {
    return false;
  }

  return true;
}, '');

jQuery.validator.unobtrusive.adapters.add('typeaheadmaxlength', ['maxlength'], function (options) {
  options.rules['typeaheadmaxlength'] = {
    maxlength: options.params['maxlength']
  };

  options.messages['typeaheadmaxlength'] = options.message;
});
// Configure Typeahead adapter  END

// Remote validation BEGIN
jQuery.validator.addMethod('remotevalidation', function (value, element, params) {
  var $el = $(element);
  var isValid;
  var validator = this;
  var evt = window.event;
  var isSubmitEvt;
  var isKeyUpEvt;
  var previous = validator.previousValue( element );

  isSubmitEvt = (evt.type == 'submit')? true : false;
  isKeyUpEvt = (evt.type == 'keyup')? true : false;

  if((isKeyUpEvt && $el.val().length >= params.maxlength) || (!isKeyUpEvt && !isSubmitEvt)) {
    var jqxhr;

    if ( previous.old === value) {
      return previous.valid;
    }

    previous.old = value;
    validator.startRequest( element );
    jqxhr = $.ajax({
      method: params.method || 'GET',
      url: params.url,
      dataType: "json",
      data: params.data,
      success: function(data) {
        isValid = data;
        previous.valid = isValid;
        if(isValid) {
          validator.prepareElement( element );
          validator.successList.push( element );
          delete validator.invalid[ element.name ];
          validator.showErrors();
        } else {
          var errors = {};
          var message = data || validator.defaultMessage( element, "remotevalidation" );
          errors[element.name] = message;
          validator.showErrors(errors);
        }
      }
    });
    validator.stopRequest( element, isValid );
  } else {
    isValid = true;
    return isValid;
  }

  return "pending";
}, '');

/*
  maxlength = Data about chars length to remotevalidation init (number).
  url = Url for endpoint (string).
  method = Query method maybe POST or GET.
  additionalfields = Add with separate comma the name of fields that data is sent (string).
*/
jQuery.validator.unobtrusive.adapters.add('remotevalidation', ['maxlength', 'url', 'method', 'additionalfields'], function (options) {
  var prefix = getModelPrefix(options.element.name);

  options.rules['remotevalidation'] = {
    maxlength: options.params['maxlength'],
    url: options.params['url'],
    method: options.params['method'],
    data : {},
  };

  $.each(splitAndTrim(options.params.additionalfields || options.element.name), function (i, fieldName) {
      var paramName = appendModelPrefix(fieldName, prefix);
      options.rules.remotevalidation.data[paramName] = function () {
          var field = $(options.form).find(":input").filter("[name='" + paramName + "']");
          return field.val();
      };
  });

  options.messages['remotevalidation'] = options.message;
});
// Remote validation END


jQuery.validator.addMethod('maxlengthcharcounter', function (value, element, params) {
  var maxLength = parseInt(params['maxlength']);
  var $control = $(element);
  var controlValue = $control.val();
  var charsCounted = controlValue.length;
  var newLines = controlValue.match(/(\r\n|\n|\r)/g);
  var addition = 0;
  var totalChars;

  if (newLines != null) {
    addition = newLines.length;
  }

  totalChars = charsCounted + addition;

  if (totalChars > maxLength) {
    return false;
  }

  return true;
}, '');

jQuery.validator.unobtrusive.adapters.add('maxlengthcharcounter', ['maxlength'], function (options) {
  options.rules['maxlengthcharcounter'] = {
    maxlength: options.params['maxlength']
  };

  options.messages['maxlengthcharcounter'] = options.message;
});





$.validator.addMethod('customexpression', function (value, element, param) {
    var dataInfo = JSON.parse($("." + param.element).html());
    var id = $("select[name='" + param.control + "']").val();
    var val = "";
    var regularExp;

    if (id == "" && id == null) {
        return false;
    }

    for (i = 0; i < dataInfo.length; i++) {
        if (dataInfo[i].id == id)
        {
            val = dataInfo[i].regularExpresion;
        }
    }

    regularExp = new RegExp(val);
    
    if (regularExp.test(value)) {
        return true;
    } else {
        return false;
    }

});

// The adapter to support ASP.NET MVC unobtrusive validation
$.validator.unobtrusive.adapters.add('customexpression', ['element', 'control'], function (options) {
    var params = {
        element: options.params.element,
        control: options.params.control,
    };

    options.rules['customexpression'] = params;
    if (options.message) {
        options.messages['customexpression'] = options.message;
    }
});











jQuery.validator.addMethod('minimumchecks', function (value, element, params) {
  var minimum = parseInt(params['minimum']);
  var selectedInputs = $('input:checked[data-name=' + $(element).data("name") + ']').length;

  return (selectedInputs >= minimum);
}, '');

jQuery.validator.unobtrusive.adapters.add('minimumchecks', ['minimum'], function (options) {
  options.rules['minimumchecks'] = {
    minimum: options.params['minimum']
  };

  options.messages['minimumchecks'] = options.message;
});






function convertBytesToMegabytes(bytes) {
  return (bytes / 1024) / 1024;
}

function getDateDMY(dateDMY) {
  var sDate;

  if (dateDMY === undefined || dateDMY === '') return null;

  sDate = dateDMY.split('/');

  return moment(sDate[2] + '-' + sDate[1] + '-' + sDate[0], 'YYYY-MM-DD');
}

function isValidDate(date) {
  var dateParse = Date.parse(date);
  return isNaN(dateParse) === false;
}

function compareDate(dateToValidate, dateToCompare, compareType) {
  switch (compareType) {
    case 'GreatherThen':
      return (dateToValidate.isAfter(dateToCompare));
    case 'GreatherThenOrEqualTo':
      return (dateToValidate.isAfter(dateToCompare) || dateToValidate.isSame(dateToCompare));
    case 'EqualTo':
      return (dateToValidate.isSame(dateToCompare));
    case 'LessThenOrEqualTo':
      return (dateToValidate.isBefore(dateToCompare) || dateToValidate.isSame(dateToCompare));
    case 'LessThen':
      return (dateToValidate.isBefore(dateToCompare));
    default:
      {
        errMsg = "Compare validation cannot be executed: '" + compareType + "' is invalid for comparetype parameter";
        console.log(errMsg);
        return false;
      }
  }
}

function getDate(date) {
  var from = date.split('/');
  return moment(new Date(from[2], from[1] - 1, from[0]));
}

// Functions for remotevalidation custom method
function getModelPrefix(fieldName) {
  return fieldName.substr(0, fieldName.lastIndexOf(".") + 1);
}

function splitAndTrim(value) {
  return value.replace(/^\s+|\s+$/g, "").split(/\s*,\s*/g);
}

function appendModelPrefix(value, prefix) {
  if (value.indexOf("*.") === 0) {
    value = value.replace("*.", prefix);
  }
  return value;
}
