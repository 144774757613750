/* DEVELOPER NOTE:

  Find and replace this string, '// DevComment: ' with '' if you want get some
  sweets debug messages. But you have to revert the changes for two reasons:
  1. We want to keep those lines in order to debug again.
  2. No production enviroment should have console o other test committed to
     production.

  Also, help us improve our debugging code. Thanks
*/
!function(global) {
  'use strict';

  var previousNotification = global.Notification;

  function Notification(options) {
    // var options = options || {};
    var $container = $('.js-notifications');
    var $htmlBody = $('html, body');
    var context = {};
    var sourceTpl;
    var hbTpl;

    function init() {
      // $container = options.$container;
      sourceTpl = $('#hbtpl-notifications').html();
      hbTpl = Handlebars.compile(sourceTpl);
    }

    /*
      content = {
        title: '',
        copy: '',
        alertType: '' ,
        $target: jQuery Object,
        closeBtn: boolean
      }
    */
    function render(content) {
      var html;
      var entry;
      // DevComment: var t0;
      // DevComment: var t1;
      // DevComment: t0 = performance.now();

      context = jQuery.extend(context, content);

      if (!('closeBtn' in context)) {
        context.closeBtn = true;
      }

      html = hbTpl(context);

      if ('$target' in content) {
        content.$target.append(html);
      } else {
        //TODO: Fragments
        $container.append(html);

        if (context != null && context.scroll === true) {
          $htmlBody.animate(
            { scrollTop: $container.offset().top - 20 },
            1000
          );
        }
      }
      // DevComment: t1 = performance.now();
      // DevComment: console.warn('Render executed in ' + (t1 - t0) + ' ms.');
    }

    function success(content) {
      context.alertType = 'success';
      context.iconType = 'check';
      render(content);
    }

    function info(content) {
        context.alertType = 'info';
        context.iconType = 'information';
      render(content);
    }

    function warning(content) {
        context.alertType = 'warning';
        context.iconType = 'alert';
      render(content);
    }

    function danger(content) {
        context.alertType = 'danger';
        context.iconType = 'error';
      render(content);
    }

    function clean() {
      $container.empty();
    }

    init();

    return {
      render: render,
      success: success,
      info: info,
      warning: warning,
      danger: danger,
      clean: clean
    }
  }

  Notification.noConflict = function noConflict() {
    global.Notification = previousNotification;
    return Notification;
  };

  global.Notification = Notification;
}(this);
