!function (global) {
  'use strict';

  // Capture context global variable.
  var previouscallSpinnerLockedNUnlocked = global.SpinnerLockedNUnlocked;

  // Global function for working with current context.
  function SpinnerLockedNUnlocked(options) {

    // Variable declaration.
    var $classButtonSpinner;
    var $idContext;
    var $modal;
    var $form;
    var $input;
    var $anchor;
    var $modalId;
    var hasModal;
    var hasForm;

    // Init for spinner.
    function init() {

      // Get the class attribute of context.
      $classButtonSpinner = options.$context.attr('class').split(' ').pop();

      // Get context of modal.
      $modal = $('.' + $classButtonSpinner).parents('div.modal');
      hasModal = $modal.length > 0;

      // Check if has modal.
      if (hasModal) {

        // Get the modal id.
        $idContext = $modal.attr('id');
      } else {

        // Get context form.
        $form = $('.' + $classButtonSpinner).parents('form');
        hasForm = $form.length > 0;

        // Get the form id.
        $idContext = $form.attr('id');
      }
    }

    //------- Begin functions exposed api --------//

    function locked() {

      // If no modal or form send context only button CTA.
      if (!hasModal && !hasForm) {

        // Call function for disabled input.
        configInputs(options.$context, true);
      } else {

        // Call function for disabled all inputs and anchors of the form context.
        configInputs($('#' + $idContext + ' :input'), true);
        configAnchors($('#' + $idContext).find('a'), true);

        if (hasModal) {

          // Call function for prevent close modal.
          configCloseModal(true);
        }
      }

      //Call function for remove icon spinner.
      configIconSpinner(true);
    }

    function unlocked() {

      // If no modal or form send context only button CTA.
      if (!hasModal && !hasForm) {

        // Call function for disabled input.
        configInputs(options.$context, false);
      } else {

        // Call function for disabled all inputs and anchors of the form context.
        configInputs($('#' + $idContext + ' :input'), false);
        configAnchors($('#' + $idContext).find('a'), false);

        if (hasModal) {

          // Call function for prevent close modal.
          configCloseModal(false);
        }
      }

      //Call function for remove icon spinner.
      configIconSpinner(false);
    }

    //-------- End functions exposed api ---------//

    //------- Begin constructor functions -------//

    // Disabled or enabled all inputs defined.
    function configInputs(inputs, isDisabled) {

      // Iterate over each of the inputs.
      inputs.each(function () {

        // Get context of the input.
        $input = $(this);

        // Config property 'disabled' to input.
        $input.prop('disabled', isDisabled);
      });
    }

    // Disabled or enabled all anchors defined.
    function configAnchors(anchors, isDisabled) {

      // Iterate over each of the anchors.
      anchors.each(function () {

        // Get context of the input.
        $anchor = $(this);

        if (isDisabled) {

          // Disabled the anchor.
          $anchor.addClass("disabled");
        } else {

          // Enabled the anchor.
          $anchor.removeClass("disabled");
        }
      });
    }

    // Config event of close/open and hide/show button of modal.
    function configCloseModal(isDisabled) {

      if (isDisabled) {

        // Hide close button.
        $modal.find('.close').hide();

        //Append container to prevent closing the modal.
        $modal.append('<div class="modal-overlay js-modal-overlay"></div>');

      } else {

        // Show close button.
        $modal.find('.close').show();

        //Remove container to allow closing the modal.
        $modal.find('.js-modal-overlay').remove();
      }
    }

    // Add or removed icon spinner.
    function configIconSpinner(isDisabled) {

      // Variable declaration.
      var $icon;
      var $iconSpinner = '<i class="fa fa-spinner fa-pulse"></i>';

      // Get the icon that can get to have input.
      $icon = $('.' +$classButtonSpinner).find('i');

      // Remove or add class 'hidden'.
      if (isDisabled) {

        //Add class and add icon spinner.
        $icon.addClass('hidden');
        $('.' +$classButtonSpinner).append($iconSpinner);
      } else {

        //Remove class and remove icon spinner.
        $icon.removeClass('hidden');
        $('.' + $classButtonSpinner).find('i.fa-spinner').remove();
      }
    }

    //------- End constructor functions -------//

    // Call init module.
    init();

    // Exposed method to control locking and unlocking.
    return {
      locked: locked,
      unlocked: unlocked
    }
  }

  // Instance no conflict between modules.
  SpinnerLockedNUnlocked.noConflict = function noConflict() {
    global.SpinnerLockedNUnlocked = previouscallSpinnerLockedNUnlocked;
    return SpinnerLockedNUnlocked;
  };

  // Module globalization.
  global.SpinnerLockedNUnlocked = SpinnerLockedNUnlocked;

}(this);
