!function(global) {
    'use strict';

    var previousAutocompleteBox = global.AutocompleteBox;

    function AutocompleteBox(options) {
        var options = options || {};
        var bhSrc;
        var $typeahead;
        var taMinLength;
        var taLimit;
        var citiesData;
        var defaultCity;
        var defaultNormalizedCity;
        var promiseBhCities;

        function normalize(str){
            return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        }

        function init() {
            $typeahead = options.$input;

            taMinLength = $typeahead.hasClass('js-searchbox-input') || options.$input.hasClass('js-keyword-autocomplete-alerts')? 3 : 0;

            if($typeahead.hasClass('js-searchbox-input') && eeGlobal.mqDetector.isDevice('xs')) {
                taLimit = 4;
            } else if($typeahead.hasClass('js-searchbox-input')) {
                taLimit = 6;
            } else {
                taLimit = 21;
            }

            /* Init bloodhound */
            // reference: http://stackoverflow.com/a/22072244/284136
            bhSrc = new Bloodhound({
                datumTokenizer: function (d) {
                    if (typeof d.normalizedName === 'undefined') {
                        const normalized = normalize(d.name);
                        return Bloodhound.tokenizers.whitespace(normalized);
                    } else {
                        const normalizedNameWithSpace = d.normalizedName.replace("-", " ");
                        return Bloodhound.tokenizers.whitespace(normalizedNameWithSpace);
                    }
                },
                queryTokenizer: function (q) {
                    const normalized = normalize(q);
                    return Bloodhound.tokenizers.whitespace(normalized);
                },
                prefetch:{
                    url: options.url,
                    filter: function(response) {
                        var dfltCityName;
                        var dfltDepartmentName;

                        if (options.$input.hasClass('js-searchbox-input') || options.$input.hasClass('js-keyword-autocomplete-alerts') || options.isHbtlpAlerts == true) {
                            return response.map(function(x) {
                                return {name: x};
                            });

                            return
                        }

                        dfltCityName = response.defaultCity.name;
                        dfltDepartmentName = response.defaultCity.departmentName;

                        return response.cities.map(function(x) {
                            return {
                                id: x.id,
                                nameToShow: (x.departmentName == "")? x.name: x.name + ', ' + x.departmentName,
                                name: (x.departmentName == "")? x.normalizedName: x.name + ', ' + x.departmentName,
                                normalizedName: x.normalizedName,
                                default: dfltDepartmentName != "" ? dfltCityName + ', ' + dfltDepartmentName : dfltCityName,
                                defaultNormalizedName: response.defaultCity.normalizedName
                            };
                        });
                    }
                }
            });

            $typeahead.typeahead(
                {
                    minLength: taMinLength,
                    highlight: true
                },
                {
                    name: 'search',
                    limit: taLimit,
                    display: function (item) {
                        if (options.isHbtlpAlerts == true) {
                            return item.name;
                        } else {
                            return (options.$input.hasClass('js-searchbox-input') || options.$input.hasClass('js-keyword-autocomplete-alerts')) ? item.name : item.nameToShow;
                        }
                    },
                    source: function (q, sync) {
                        if (q === '') {
                            sync(bhSrc.index.all());
                        }
                        else {
                            bhSrc.search(q, sync);
                        }                     
                    }
                }
            );

            // Getting defaultCity
            if(!$typeahead.hasClass('js-searchbox-input')) {
                promiseBhCities = bhSrc.initialize();
                promiseBhCities.done(function() {
                    citiesData = bhSrc.all();
                    defaultCity = citiesData[0].default;
                    defaultNormalizedCity = citiesData[0].defaultNormalizedName;
                });
            }
        }

        function setDefaultCity() {
            return defaultCity;
        }

        function setDefaultNormalizedCity() {
            return defaultNormalizedCity;
        }

        init();

        return {
            promiseBhCities: promiseBhCities,
            setDefaultCity: setDefaultCity,
            setDefaultNormalizedCity: setDefaultNormalizedCity
        }
    }

    AutocompleteBox.noConflict = function noConflict() {
        global.AutocompleteBox = previousAutocompleteBox;
        return AutocompleteBox;
    };

    global.AutocompleteBox = AutocompleteBox;
}(this);
