!function(global) {
  'use strict';

  var previouscallSpinner = global.Spinner;

  function Spinner(options) {
    var options = options || {};
    var $spinner;
    var spinnerState;

    function init() {
      $spinner = options.$selectSpinner;
      spinnerState = false;

      if (options.initSpinner) {
        toggleSpinner();
      }
    }

    function toggleSpinner() {
      if (spinnerState === true) {
        $spinner.addClass('hidden');
        spinnerState = false;
      } else {
        $spinner.removeClass('hidden');
        spinnerState = true;
      }
    }

    function showSpinner() {
      $spinner.removeClass('hidden');
      spinnerState = true;
    }

    function hideSpinner() {
      $spinner.addClass('hidden');
      spinnerState = false;
    }

    init();

    return {
      toggle: toggleSpinner,
      show: showSpinner,
      hide: hideSpinner
    }

  }

  Spinner.noConflict = function noConflict() {
    global.Spinner = previousSpinner;
    return Spinner;
  };

  global.Spinner = Spinner;
}(this);
