(function() {
  $(["requiredif"]).each(function(index, validationName) {
    $.validator.addMethod(validationName,
      function(value, element, parameters) {
        // Get the name prefix for the target control, depending on the validated control name
        var prefix = "";
        var lastDot = element.name.lastIndexOf(".");
        if (lastDot != -1) {
          prefix = element.name.substring(0, lastDot + 1).replace(".", "_");
        }
        var dependentProperty = parameters["dependentproperty"].replace("_", ".");
        var name = prefix + dependentProperty;
        // get the target value
        var targetvalue = parameters["targetvalue"];
        targetvalue = (targetvalue == null ? "" : targetvalue).toString();
        // get the actual value of the target control
        var control = $("[name='" + name + "']");

        if (control.length == 0 && prefix.length > 0) {
          // Target control not found, try without the prefix
          control = $("[name='" + dependentProperty + "']");
        }
        if (control.length > 0) {
          var actualvalue = "";
          if (control.is("checkbox"))
            actualvalue = control.is(":checked") ? "true" : "false";
          else {
            actualvalue = control.val();
          }

          if (targetvalue == null || targetvalue == undefined || actualvalue == null || actualvalue == undefined)
            return false;

          // if the condition is true, reuse the existing validator functionality
          if (targetvalue.toLowerCase() === actualvalue.toLowerCase()) {
            var rule = parameters["rule"];
            var ruleparam = parameters["ruleparam"];
            return $.validator.methods[rule].call(this, value, element, ruleparam);
          }
        }
        return true;
      }
    );

    $.validator.unobtrusive.adapters.add(validationName, ["dependentproperty", "targetvalue", "rule", "ruleparam"], function(options) {
      var rp = options.params["ruleparam"];
      options.rules[validationName] = {
        dependentproperty: options.params["dependentproperty"],
        targetvalue: options.params["targetvalue"],
        rule: options.params["rule"]
      };
      if (rp) {
        options.rules[validationName].ruleparam = rp.charAt(0) == "[" ? eval(rp) : rp;
      }
      options.messages[validationName] = options.message;
    });
  });
})();
