!function (global) {
    'use strict';

    var previousPasswordRevealer = global.PasswordRevealer;

    function PasswordRevealer(options) {
        var options = options || {};
        var $target;

        function init() {
            $target = options.$target;

            $target
              .wrap('<div class="password-wrapper"></div>')
              .after('<span class="password-revealer js-password-revealer"><i class="fa fa-eye"></i></span>');

            $target.on("contextmenu", function (e) {
                return false;
            });

           
            $target.on('keyup change', function () {
                var $this = $(this);
                var $reveal = $this.nextAll('.js-password-revealer');

                if ($this.val() == '') {
                    $reveal.removeClass('active');
                } else {
                    $reveal.addClass('active');
                }
            });

            $target.nextAll('.js-password-revealer').on('click', function () {
                var $this = $(this);
                var $icon = $this.find('.fa');
                var inputField = $this.prev();

                if ($icon.hasClass('fa-eye')) {
                    $icon.removeClass('fa-eye').addClass('fa-eye-slash');
                    $this.prevAll('input[type="password"]').attr('type', 'text');
                } else {
                    $icon.removeClass('fa-eye-slash').addClass('fa-eye');
                    $this.prevAll('input[type="text"]').attr('type', 'password');

                }

                inputField.bind('cut copy paste', function (e) {
                    e.preventDefault();
                });

                inputField.prev().on("contextmenu", function (e) {
                    return false;
                });

            })
        }

        init();
        // return {}

    }

    PasswordRevealer.noConflict = function noConflict() {
        global.PasswordRevealer = previousPasswordRevealer;
        return PasswordRevealer;
    };

    global.PasswordRevealer = PasswordRevealer;
}(this);
